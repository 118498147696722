import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Col, Container, Row } from 'reactstrap';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import { SECONDARY } from '../../utils/colors';

const Title = styled.h1`
  font-size: 180px;
  line-height: 150px;
`;

const Section = styled.section`
  color: ${SECONDARY};
  padding: 200px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 80%;
  text-align: center;
`;

const NotFoundPage = ({ location }) => {
  const { file = {} } = useStaticQuery(graphql`
    {
      file(name: { in: "404" }) {
        publicURL
      }
    }
  `);
  return (
    <Layout location={location}>
      <SEO title="404: Not found" />
      <Section style={{ backgroundImage: `url(${file.publicURL})` }}>
        <Container>
          <Row>
            <Col>
              <Title>404</Title>
              <h2>Página não encontrada</h2>
              <Link to="/">Retorne a página inicial</Link>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default NotFoundPage;
